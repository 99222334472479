.professor {
	padding: 1em;
	width: 100%;
	height: 100%;
	overflow: hidden;
    max-width: 100%;
}

.professorInfo {
	display: flex;
	text-transform: capitalize;

	.lastName {
		margin-left: 0.4em;
		text-transform: uppercase;
	}
}
