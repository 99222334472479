.details {
    display: grid;
    grid-template-columns: 1fr 3fr;
    padding: 1em;
    padding-top: 0;
    padding-bottom: 4em;
}

h2 {
    color: var(--PRIMARY);
}

h1 {
    color: var(--PRIMARY-120);
    font-size: 2em !important;
    margin: 0;
}
