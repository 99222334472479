.delete{
    display: flex;
    align-items: center;

    p{
        margin: 0;
        margin-left: 0.5em;
    }
}

.title {
    display: flex;
    justify-content: center;
    flex-direction: row;
    // align-items: center;

    p {
        margin-left: 1em;
    }
}

.left {
    display: flex;
    align-items: center;

    a {
        display: flex;

        svg {
            font-size: 2em !important;
        }
    }
}

.info {
    margin-left: 20px;
}