.navigation {
	max-height: 100vh;
	background-color: var(--PRIMARY-120);
	position: sticky;
	top: 0;
	color: white;
	width: 4vw;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	grid-row: span 2;
	.pointer {
		cursor: pointer;
	}
	a {
		color: white;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0.5em;
		padding-top: 1em;
    	padding-bottom: 1em;

		span {
			height: 2em;
			width: 2em;
			svg {
				height: 100%;
				width: 100%;
			}
		}
	}
	a:last-child {
		padding-bottom: 1em;
	}

	.main {
		a {
			p {
				font-size: 1em;
			}
		}
	}

	.logo {
		display: flex;
		justify-content: center;
		padding-bottom: 3.7em;
		padding-top: 1em;
		img {
			border-radius: 50%;
			border: 2px solid white;
		}
	}

	.active {
		background-color: white;
		color: var(--PRIMARY-120);
		fill: var(--PRIMARY-120);
		border-left: 5px solid var(--PRIMARY-120);
		border-top-left-radius: 30%;
		border-bottom-left-radius: 30%;
	}

	p {
		margin: 0;
	}

	[role='menu'] {
		border: none;
	}

	[role='button'] {
		height: 100% !important;
		padding: 0;
		background-color: var(--PRIMARY-120);
		margin: 0;
	}

	i {
		display: none;
	}
}
