.container {
	height: 100%;
	display: flex;
}

.left,
.right {
	width: 100%;
}

.right {
	background: transparent linear-gradient(90deg, #ff5555 0%, #ffce48 100%) 0% 0%
		no-repeat padding-box;

	svg {
		height: 100vh;
	}
}
