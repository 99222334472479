.gallery {
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	justify-items: center;

	@media (max-width: 2000px) {
		grid-template-columns: repeat(6, 1fr);
	}

	@media (max-width: 1725px) {
		grid-template-columns: repeat(5, 1fr);
	}

	@media (max-width: 1460px) {
		grid-template-columns: repeat(4, 1fr);
	}

	@media (max-width: 1195px) {
		grid-template-columns: repeat(3, 1fr);
	}

	@media (max-width: 925px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media (max-width: 555px) {
		grid-template-columns: repeat(1, 1fr);
	}
}
