.professors {
	overflow: auto;
	.professor .ant-card .ant-card-cover {
		@media (max-width: 555px) {
			display: none;
		}
	}

	td {
		img {
			height: 3em;
			width: auto;
		}
	}
}
