.pathways {
	overflow: auto;
	.student .ant-card .ant-card-cover {
		@media (max-width: 555px) {
			display: none;
		}
	}

	.ant-card {
		height: 100%;
	}
}
