button {
    background-color: var(--PRIMARY) !important;
    border-color: var(--PRIMARY) !important;
    &:focus {
        box-shadow: none !important;
    }
}

.fc-button-active {
    background-color: var(--WHITE) !important;
    color: var(--PRIMARY) !important;
}