.ant-select {
    width: 10%;
}

p {
    margin: 0;
}

// .constraints {
//     padding-bottom: 3em;
//     display: flex;
//     flex-direction: column;
// }

.day {
    display: grid;
    grid-template-columns: 100px 1fr;

    label {
        justify-self: flex-end;
        margin-right: 1em;
    }
}
