.addStudent {
	padding: 1em;
	width: 100%;
	height: 100%;

	span {
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		svg {
			width: 30%;
			height: 30%;
		}
	}
}
